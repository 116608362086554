import React, {useState} from "react";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import BigNumber from "bignumber.js";
import config from "../../config";

const Image = ({src, alt}) => {
    const [errored, setErrored] = useState(false);

    if (errored) {
        return (<svg
            className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
            preserveAspectRatio="none"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 200 200"
            aria-hidden="true"
        >
            <path
                vectorEffect="non-scaling-stroke"
                strokeWidth="1"
                d="M0 0l200 200M0 200L200 0"
            ></path>
        </svg>);
    } else {
        return (<img
            className="flex-shrink-0 object-contain w-full h-full mx-auto"
            src={`${config.cdn}${config.baseUrlMedia}${src}`}
            alt={alt}
            onError={setErrored}
        />);
    }
};

export default function CartItemFree() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const {calcusoAI, fakeCart} = globalState;
    const {items} = fakeCart;
    const {settings} = calcusoAI;
    const {cart_rules} = settings;

    const cartItems = Object.keys(items)
        .map((key) => {
            return {
                ...items[key],
            };
        })
        .sort(function (x, y) {
            return x.created_at - y.created_at;
        });

    const total = cartItems.reduce((accumulator, {price, qty}) => {
        return new BigNumber(accumulator).plus(
            new BigNumber(price).multipliedBy(new BigNumber(qty))
        );
    }, 0);

    const cartRule = Object.values(cart_rules).find((rule) => rule.quote_source === "calcuso_ai");


    return (
        <>
            {cartRule && Number(cartRule.cart_total) > 0 && Number(total) > Number(cartRule.cart_total) ? (
                <>
                    <li className="flex py-6">
                        <div className="flex-shrink-0 w-16 overflow-hidden md:w-24 md:h-24">
                            <Image src={cartRule?.product?.image} alt={cartRule?.product?.name}/>
                        </div>

                        <div className="flex flex-col flex-1 ml-4 space-y-4">
                            <div>
                                <h3 className="md:text-base text-sm font-medium text-gray-900 overflow-ellipsis">
                                    {cartRule?.product?.name}
                                </h3>
                            </div>
                            <div className="flex items-end justify-end flex-1 text-sm">
                                <p className="md:text-base text-sm font-medium text-cal-primary-celadon-green-008E8D">
                                    {t("Free of charge")}
                                </p>
                            </div>
                        </div>
                    </li>
                </>
            ) : null}
        </>
    );
}
