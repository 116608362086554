import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import BOX_ICON from "../../images/calcuso-ai/box-icon.png";
import {FREE_SHIPPING_AMOUNT} from "../../constants/shippingMethod";

export default function FreeShipBanner() {
    const {t} = useTranslation();

    return (
        <>
            <div className="text-sm bg-cal-primary-floral-white-F5F0E1 flex justify-center py-1">
                <p>
                    <img src={BOX_ICON} className="w-4 h-4 inline-block mr-2"/>
                    {t('Free shipping from {{freeShippingAmount}} €', {freeShippingAmount: FREE_SHIPPING_AMOUNT})}
                </p>
            </div>
        </>
    );
};
