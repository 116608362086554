import React from 'react';
import classNames from "classnames";

const PlaceHolderLoading = ({index, item}) => {
    return (
        <div class="px-2 py-2 w-full rounded-lg md:px-4 md:pt-4 md:max-w-7xl">
            <div role="status" class="animate-pulse">
                <div class="flex space-x-2">
                    <div>
                        <div className="flex md:h-full items-center">
                            <div className={classNames({
                                "flex justify-center items-center w-9 h-9 rounded-full font-medium bg-cal-primary-celadon-green-E5F8F7": true,
                                "bg-cal-primary-celadon-green-E5F8F7": item?.main_product !== null,
                                "bg-gray-100": item?.main_product === null
                            })}>
                                <span className="font-bold text-xl">{index + 1}</span>
                            </div>

                            <div className="pl-2 flex-1 min-w-0">
                                <span className="md:text-base text-sm font-bold break-words">
                                  {item.text}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="grid grid-cols-3">
                    <div class="col-span-1">
                        <div class="flex items-center justify-center w-full h-32 bg-gray-300 dark:bg-gray-700">
                            <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col-span-2 pl-4">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                    </div>
                </div>
                <div class="flex pt-4 md:pt-3">
                    <div class="w-1/5 md:w-1/6 mx-auto">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                    </div>
                    <div class="w-1/5 md:w-1/6 mx-auto">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaceHolderLoading;
