import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import FakeCart from "../../../components/FakeCart";
import SideBarMenu from "../../../components/AI/SideBarMenu/SideBarMenu";
import ProductModal from "../../../components/ProductModal";
import useGlobal from "../../../state/useGlobal";
import history from "../../../routes/history";
import toast from "react-hot-toast";
import FakeCartModal from "../../../components/FakeCartModal";
import {Redirect} from "react-router-dom";
import apiCRM from "../../../services/apiCRM";
import api from "../../../services/api";
import classNames from "classnames";
import TeacherEditionV3 from "../../../components/AI/TeacherEdition/TeacherEditionV3";
import CardItem from "../../../components/AI/CardItem/CardItem";
import Waiting from "../../../components/AI/Waiting/Waiting";
import Config from "../../../config";
import GO_IMG from "../../../images/calcuso-ai/group-order.png";
import mixpanel from "mixpanel-browser";
import ScrollToFieldError from "../../../components/ScrollToFieldError";
import Input from "../../../components/Forms/Input";
import * as Yup from "yup";
import {Formik} from "formik";
import PEEKAPOO_MONKEY from "../../../images/calcuso-ai/peekaboo-monkey.png";
import CardFreeProduct from "../../../components/AI/CardItem/CardFreeProduct";
import FreeShipBanner from "../../../components/FreeShipBanner/FreeShipBanner";

export default function ProductScreen() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [globalState, globalActions] = useGlobal();
    const {calcusoAI, teacherEdition, checkout} = globalState;
    const {uuid, is_analyzed, is_parent_letter, is_not_found_product, group_order_school_code, is_calcuso_parent_letter} = calcusoAI;
    const [cartItem, setCartItem] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(-1);
    const [items, setItems] = useState(null);
    const [cartRules, setCartRules] = useState(null);
    const [settings, setSettings] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
    const [isLoadingISBN, setIsLoadingISBN] = useState(true);
    const [preCheckText, setPreCheckText] = useState('');
    const {store} = checkout;
    const fakeItems = new Array(5).fill({});

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isWaiting) {
                e.preventDefault();
                e.returnValue = t('Are you sure you want to leave?');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isWaiting]);

    useEffect(() => {
        const init = async () => {
            const calcusoAI = globalState.calcusoAI;
            const calcusoAIShare = globalState.calcusoAIShare;

            mixpanel.track_pageview({
                page: "product",
                uuid: calcusoAI.uuid ?? null,
                refer_code: calcusoAIShare.refer_code ?? null
            });

            if (!uuid) {
                history.push('/');
                return;
            }

            if (is_analyzed === false) {
                setIsWaiting(true);
                analyze();
                globalActions.setIsAnalyzed(true);
            }

            if (is_analyzed === true) {
                setIsLoadingISBN(false);
                await initProductScreen(calcusoAI);
                setIsLoading(false);
            }

            initDisplayProductCard();
        };

        init();
    }, []);

    const analyze = () => {
        let openedModal = false;
        let numberRequestProductTag = 0;

        const analyzeNext = async () => {
            const response = await callAPI();
            const {status, step, precheck_text, translated_precheck_text} = response.data;
            globalActions.setStep(step);

            if (translated_precheck_text === '') {
                setPreCheckText(precheck_text);
            } else {
                setPreCheckText(translated_precheck_text);
            }

            const document = response.data?.apiData?.documents[0];
            if (document) {
                globalActions.addPreviousUuid(uuid, document.original_url ?? document.document_url);
            }

            if (status === 'pending') {
                setTimeout(analyzeNext, 5000);
            }

            if (status === 'processing') {
                if (step === 'TaggingProductsJob') {
                    if (numberRequestProductTag === 0) {
                        let aiData = await globalActions.reStructureCalcusoAI(response.data);
                        const {items} = aiData;
                        setItems(items);
                    }

                    if (numberRequestProductTag >= 1) {
                        setIsWaiting(false);
                    }

                    numberRequestProductTag = numberRequestProductTag + 1;
                }

                setTimeout(analyzeNext, 5000);
            }

            if (status === 'completed') {
                setIsWaiting(false);
                setIsLoading(false);
                setIsLoadingISBN(false);
                openedModal = true;

                let aiData = await globalActions.reStructureCalcusoAI(response.data);
                await initProductScreen(aiData);
                await handleISBN();
            }

            if (status === 'error') {
                openedModal = true;

                let aiData = await globalActions.reStructureCalcusoAI(response.data);
                if (aiData.is_not_found_product === true) {
                    setIsWaiting(false);
                } else {
                    setIsWaiting(false);
                    setIsLoading(false);
                    setIsLoadingISBN(false);

                    await initProductScreen(aiData);
                    await handleISBN();
                }
            }
        };

        analyzeNext();
    };

    const callAPI = async () => {
        return apiCRM.get('/ai/request/' + uuid);
    };

    const verifyParentLetter = (response) => {
        const {apiData} = response;
        const {documents} = apiData;
        let allIsNotParentLetter = true;

        documents.map((document) => {
            if (document.is_parent_letter !== true && document.is_parent_letter !== null) {
                allIsNotParentLetter = false;
            }
        });

        return allIsNotParentLetter;
    };

    const initProductScreen = async (aiData) => {
        const {items, settings} = aiData;
        const {cart_rules} = settings;
        setItems(null);
        setItems(items);
        setCartRules(cart_rules);
        setSettings(settings);

        if (!is_analyzed) {
            items.forEach((_, index) => {
                let duration = 1000;

                setTimeout(() => {
                    if (index === 0) {
                        duration = 5000;
                    }

                    if (index > 1) {
                        setCurrentCardIndex(items?.length - 1);
                    } else {
                        setCurrentCardIndex(index);
                    }
                }, (index + 1) * duration);
            });
        } else {
            setCurrentCardIndex(items.length - 1);
        }

        initCartItems();
    };

    const initCartItems = () => {
        const {fakeCart} = globalState;
        const {items} = fakeCart;
        setCartItem(items);
    }

    const toggleCardVisibility = (itemId) => {
        const {simpleItems} = calcusoAI;

        simpleItems.map((item) => {
            if (item.item_id === itemId && item?.productIds?.length === 0) {
                globalActions.setIsDisplay(itemId);
            }
        });

        items.map((item) => {
            if (item.item_id === itemId) {
                const cartProduct = cartItem[item?.main_product?.product_id];

                if (cartProduct !== undefined) {
                    globalActions.setIsDisplay(itemId);
                }
            }
        });
    };

    const displayProductCard = (index) => {
        const {simpleItems} = calcusoAI;

        if (simpleItems === undefined) {
            return true;
        }

        return simpleItems[index]?.is_display;
    };

    const initDisplayProductCard = () => {
        if (items) {
            items.map((aiItem) => {
                const cartProduct = cartItem[aiItem?.main_product?.product_id];

                if (!cartProduct) {
                    globalActions.setIsDisplay(aiItem.item_id, true);
                }
            });
        }
    }

    const handleNextStep = () => {
        const {fakeCart} = globalState;
        const {items} = fakeCart;

        if (Object.keys(items).length === 0) {
            toast.error(t('Please add at least one product to the cart'));
        } else {
            history.push('/cart/');
        }
    }

    const handleBack = () => {
        history.push('/upload/');
    }

    const handleBackToWebshop = () => {
        window.location.href = Config.stores[store?.id];
    }
    const handleISBN = async () => {
        await getIsbnData();
    }

    const getIsbnData = async () => {
        let numberRequest = 0;

        const getIsbn = async () => {
            const response = await apiCRM.get('/ai/request/' + uuid + '/book');
            let apiData = globalActions.handleIsbnItem(response.data);
            setItems(apiData.items);

            if (response.step === 'FindIsbnBookFromM2Job' || numberRequest <= 2) {
                numberRequest = numberRequest + 1;
                setTimeout(getIsbn, 3000);
            }
        };

        await getIsbn();
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            <div class="flex flex-col">
                <Helmet>
                    <title>{('Calcuso')}</title>
                </Helmet>
                <div className="sticky top-0 bg-cal-primary-celadon-green-E5F8F7 z-20">
                    {!isWaiting && (
                        <FreeShipBanner />
                    )}
                    <HeaderAI confirm={true} title={('CALCUSO')} subTitle={t('MatchBuddy')} setOpen={setOpen} isShowSmallMenu={!isWaiting}>
                    </HeaderAI>
                </div>

                <div className={classNames({
                    "z-10": isWaiting,
                    "transition-opacity duration-500 opacity-0 hidden": !isWaiting,
                })}>
                    <Waiting preCheckText={preCheckText}/>
                </div>

                <div className={classNames({
                    "absolute inset-0 bg-cal-primary-celadon-green-E5F8F7 bg-opacity-70 flex items-center justify-center": true,
                    "flex z-10": isLoadingRedirect,
                    "transition-opacity duration-500 opacity-0 z-0": !isLoadingRedirect,
                })}>
                    <div class="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900"></div>
                </div>

                <div>
                    {uuid ? (
                        <div className={classNames({
                            "hidden": isWaiting,
                            "transition-opacity duration-500 opacity-100 block": !isWaiting,
                        })}>
                            <div className="flex flex-col h-full min-h-screen">
                                <div class="flex flex-col items-center flex-1 h-full pb-28 md:pt-10 relative">
                                    {items?.length > 0 && (
                                        <>
                                            <div class="font-bold md:text-2xl text-lg md:pb-5 pb-0">
                                                {t('Choose your products')}
                                            </div>
                                            {items.map((item, index) => (
                                                    <CardItem isLoadingISBN={isLoadingISBN} isLoading={isLoading} index={index} item={item} displayProductCard={displayProductCard} toggleCardVisibility={toggleCardVisibility} currentCardIndex={currentCardIndex} cartItem={cartItem}/>
                                                )
                                            )}
                                            {cartRules && Object.values(cartRules).map((rule) => (
                                                <CardFreeProduct freeProduct={rule.product} cartTotal={rule.cart_total}/>
                                            ))}
                                        </>
                                    )}

                                    {!isWaiting && is_not_found_product && !is_calcuso_parent_letter && (
                                        <div className="flex justify-center items-center flex-1 h-full">
                                            <div className="font-medium text-lg md:text-2xl text-center space-y-4 pb-32 px-6">
                                                <div className="flex flex-col space-y-4 items-center bg-white rounded-lg py-4 px-6">
                                                    <div>
                                                        <img className="w-16" src={PEEKAPOO_MONKEY} />
                                                    </div>
                                                    <div>
                                                        {t("I think this is not a parent letter.")}
                                                    </div>
                                                    <div>
                                                        {preCheckText}
                                                    </div>

                                                    <div className="pt-4">
                                                        <Button
                                                            onClick={handleBack}
                                                            type="button"
                                                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                            size={window.innerWidth < 640 ? "base" : "xl"}
                                                        >
                                                            <span>{t("Try again")}</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="cursor-pointer text-sm text-cal-primary-celadon-green-008E8D" onClick={handleBackToWebshop}>{t("Back to the webshop")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!isWaiting && is_calcuso_parent_letter && (
                                        <div class="block">
                                            <div className="flex flex-col w-full items-center my-5">
                                                <img
                                                    src={GO_IMG}
                                                    alt="Group Order"
                                                    className="flex-shrink-0 w-auto h-72"
                                                />
                                            </div>
                                            <div class="font-medium text-lg text-center px-6 pt-4">
                                                {t("You have uploaded a parent letter from the CALCUSO group order.")}
                                            </div>

                                            {group_order_school_code && (
                                                <>
                                                    <div class="font-medium text-lg text-center px-6 pt-4">
                                                        <span className="text-red-600">{t("Schoolcode")}: </span>
                                                        {group_order_school_code}
                                                    </div>
                                                    <div className="pt-4 flex justify-center">
                                                        <Button
                                                            type="button"
                                                            color="mx-auto bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                                            size={window.innerWidth < 640 ? "base" : "xl"}
                                                            onClick={() => document.location.href = Config.apiGO + '/#/group-orders/' + group_order_school_code}
                                                        >
                                                            {t("Go to group order")}
                                                        </Button>
                                                    </div>
                                                </>
                                            )}

                                            {!group_order_school_code && (
                                                <>
                                                    <div class="font-medium text-lg text-center px-6 pt-4">
                                                        {t("Input the school code in the top right corner of the parent letter.")}
                                                    </div>
                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={{
                                                            school_code: '',
                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            school_code: Yup.string().required(t("This field is required")),
                                                        })}
                                                        onSubmit={async (values) => {
                                                            setIsLoadingRedirect(true)
                                                            const response = await api.get('/calcuso-group-order/' + values.school_code);

                                                            if (response.data.payload?.school_code) {
                                                                document.location.href = Config.apiGO + '/#/group-orders/' + values.school_code;
                                                            } else {
                                                                await setIsLoading(false)
                                                                toast.error(t("The school code is not correct"));
                                                            }
                                                        }}
                                                        validateOnChange>
                                                        {({errors, touched, handleSubmit, values, handleChange, handleBlur, isValid, setValues}) => (
                                                            <form onSubmit={handleSubmit} className="w-full px-2 md:px-4 lg:px-8">
                                                                <ScrollToFieldError/>
                                                                <div className="px-4 mt-3 md:px-6 lg:px-8">
                                                                    <div className="flex flex-col space-y-4 w-96 mx-auto">
                                                                        <Input
                                                                            name="school_code"
                                                                            placeholder={t("123abc456")}
                                                                            onChange={e => handleChange(e)}
                                                                            onBlur={handleBlur}
                                                                            value={values.school_code}
                                                                            required
                                                                            error={touched.school_code && errors.school_code}
                                                                            errorMessage={errors.school_code}
                                                                        />

                                                                        <Button
                                                                            type="submit"
                                                                            color="mx-auto bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                                                            size={window.innerWidth < 640 ? "base" : "xl"}
                                                                        >
                                                                            <span>{t("Go to group order")}</span>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        )}
                                                    </Formik>
                                                </>
                                            )}
                                        </div>
                                    )}
                                < /div>
                            </div>
                            <div className={classNames({
                                "fixed inset-x-0 bottom-0 opacity-0": true,
                                "transition-opacity duration-300 opacity-100": !isWaiting && items?.length > 0,
                            })}>
                                {teacherEdition?.is_teacher ? (
                                    <TeacherEditionV3/>
                                ) : (
                                    <div className="border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                                        <div className="max-w-2xl px-4 py-2 mx-auto lg:max-w-7xl md:px-54 md:py-6 lg:px-8 lg:py-8">
                                            <div className="flex items-center justify-between space-x-4">
                                                <FakeCart showMobile/>
                                                <div className="flex items-center justify-end space-x-4">
                                                    <Button
                                                        type="button"
                                                        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                        size={window.innerWidth < 640 ? "base" : "xl"}
                                                        onClick={handleNextStep}
                                                    >
                                                        <span>{t("next")}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <ProductModal/>
                            <FakeCartModal/>
                            <SideBarMenu open={open} setOpen={setOpen}/>
                        </div>
                    ) : (
                        <Redirect to="/"/>
                    )}
                </div>
            </div>
        </AppLayout>
    );
};
