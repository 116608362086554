import React, {useEffect, useState, useRef} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import CameraIcon from "@heroicons/react/outline/CameraIcon";
import CloudUploadIcon from "@heroicons/react/outline/CloudUploadIcon";
import MinusCircleIcon from "@heroicons/react/solid/MinusCircleIcon";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/Loading";
import apiCRM from "../../../services/apiCRM";
import useGlobal from "../../../state/useGlobal";
import toast from "react-hot-toast";
import history from "../../../routes/history";
import {Document, Page, pdfjs} from "react-pdf";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "../../../config";
import rotateIcon from "../../../images/calcuso-ai/rotate_icon.svg";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import i18next from "i18next";

export default function UploadingScreen() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [rotateAngles, setRotateAngles] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const [isReview, setIsReview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [loadingButton, setLoadingButton] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const recaptchaRef = useRef(null);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const handleFileChange = (event) => {
        setIsLoading(true);
        const filesArray = Array.from(event.target.files);
        setSelectedFiles(filesArray);
        setRotateAngles(Array(filesArray.length).fill(0));
        setIsReview(true);
        setIsLoading(false);
    };

    const handleStartAnalyzing = async () => {
        try {
            let locale = i18next.language;
            let token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            setLoadingButton(true);
            const formData = new FormData();
            formData.append('g-recaptcha-response', token);
            formData.append('locale', locale ?? 'de');

            selectedFiles.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
                formData.append(`rotateAngles[${index}]`, rotateAngles[index]);
            });

            let response = await apiCRM.post('/ai/request', {
                'content-type': 'multipart/form-data'
            }, formData);

            globalActions.setCalcusoAI({
                ...globalState.calcusoAI,
                uuid: response.data.id
            });

            globalActions.setCalcusoAIShare({
                uuid: response.data.id
            });

            history.push('/product');
        } catch (error) {
            toast.error(t('An error occurred during upload'));
            setIsReview(false);
            setLoadingButton(false);
        }
        setLoadingButton(false);
    };

    const removePicture = (index) => {
        const newFiles = selectedFiles.filter((file, i) => i !== index);
        setSelectedFiles(newFiles);
        const newRotateAngles = rotateAngles.filter((angle, i) => i !== index);
        setRotateAngles(newRotateAngles);
        const newDimensions = dimensions.filter((dimension, i) => i !== index);
        setDimensions(newDimensions);

        if (newFiles.length === 0) {
            setIsReview(false);
        }
    };

    const rotatePicture = (index) => {
        const newRotateAngles = [...rotateAngles];
        newRotateAngles[index] = (rotateAngles[index] + 90) % 360;
        setRotateAngles(newRotateAngles);
    }

    const handleTakeNewPicture = (event) => {
        setIsLoading(true);
        const addedFiles = Array.from(event.target.files);

        const newFiles = selectedFiles.concat(addedFiles);
        setSelectedFiles(newFiles);

        const addedRotateAngles = Array(addedFiles.length).fill(0);
        const newRotateAngles = rotateAngles.concat(addedRotateAngles);
        setRotateAngles(newRotateAngles);

        setIsLoading(false);
    };

    useEffect(() => {
        globalActions.initCalcusoAI();
        globalActions.initFakeCart();
        globalActions.initCheckout();

        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "upload",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        setIsLoading(false)
    }, []);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    const isPdf = (file) => {
        return file.type === 'application/pdf';
    }

    const isImage = (file) => {
        return file.type.includes('image');
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>{('Calcuso')}</title>
                    </Helmet>
                    <div class="hidden">
                        <ReCAPTCHA
                            theme="dark"
                            style={{display: "inline-block"}}
                            ref={recaptchaRef}
                            sitekey={Config.siteCaptchaKey}
                            size="invisible"
                        />
                    </div>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={false} title={('CALCUSO')} subTitle={t('MatchBuddy')}>
                        </HeaderAI>
                        {isReview ? (
                            <>
                                <div>
                                    <div class="pt-14 md:w-2/3 lg:w-full mx-auto xl:w-1/2">
                                        <div
                                            class="text-xl font-bold tracking-wide text-center md:text-2xl text-cal-primary-gunmetal-black-202833 px-6">
                                            {t('Your parents’ letter')}
                                        </div>
                                        <div
                                            class="text-xs font-medium tracking-wide text-center md:text-2xl text-cal-primary-celadon-green-01A7A3 px-6 my-4">
                                            {t('Please check the quality. Rotate the image to the correct reading position.')}
                                        </div>
                                        <div class="h-2/3 md:h-full md:max-w-3xl mx-auto lg:max-w-7xl">
                                            <div>
                                                <span className={`sr-only rotate-90 rotate-180 rotate-270`}></span>
                                                <div className={`${selectedFiles.length === 1 ? `justify-center` : selectedFiles.length < 3 ? `md:justify-center` : `md:grid md:grid-cols-2 lg:grid lg:grid-cols-3`} overflow-x-auto md:overflow-x-hidden flex flex-row px-2 gap-3`}>
                                                    {selectedFiles.map((file, index) => (
                                                        <div class="inline-block h-72 pt-3">
                                                            <div className={classNames({
                                                                "relative px-1.5": true,
                                                                "pb-2 h-64  pt-4 md:py-4 w-60 md:w-60 lg:w-64 mx-auto overflow-y-scroll": isPdf(file),
                                                                "w-64 h-64 mx-auto bg-gray-400 bg-opacity-30 rounded-lg shadow-md": isImage(file),
                                                            })}>
                                                                <div class="w-full h-full flex content-center">
                                                                    {isPdf(file) ? (
                                                                        <Document
                                                                            file={file}
                                                                            onLoadSuccess={onDocumentLoadSuccess}
                                                                        >
                                                                            {Array.from(new Array(numPages), (el, index) => (
                                                                                <Page
                                                                                    scale={0.38}
                                                                                    key={`page_${index + 1}`}
                                                                                    pageNumber={index + 1}
                                                                                />
                                                                            ))}
                                                                        </Document>
                                                                    ) : (
                                                                        <img className={`max-w-64 max-h-64 transform m-auto rotate-${rotateAngles[index]}`} key={index}
                                                                             src={URL.createObjectURL(file)}
                                                                             alt={`Image ${index}`}/>
                                                                    )}
                                                                </div>
                                                                {isImage(file) ? (
                                                                    <div
                                                                        onClick={() => rotatePicture(index)}
                                                                        className="h-10 w-10 bg-teal-700 absolute -bottom-5 left-0 right-0 ml-auto mr-auto cursor-pointer rounded-full p-1.5">
                                                                        <img src={rotateIcon} alt="Rotate" className="w-full"/>
                                                                    </div>
                                                                ) : null}
                                                                <MinusCircleIcon
                                                                    onClick={() => removePicture(index)}
                                                                    className="h-6 w-6 text-cal-secondary-fiery-rose-f74e6c absolute top-0.5 right-0.5 cursor-pointer"/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="space-y-4">
                                            <div class="w-4/5 md:w-2/5 mx-auto pt-10 space-y-6">
                                                <Button
                                                    type="button"
                                                    color="bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                                    size={window.innerWidth < 640 ? "base" : "xl"}
                                                    full={true}
                                                    loading={loadingButton}
                                                    onClick={handleStartAnalyzing}
                                                >
                                                    {t('Start product search')}
                                                </Button>

                                                <div class="w-full text-center">
                                                    {loadingButton ? (
                                                        <span className="pl-2 text-lg cursor-not-allowed text-cal-primary-celadon-green-01A7A3 opacity-75 font-bold">
                                                            {t('Take new picture')}
                                                        </span>
                                                    ) : (
                                                        <label
                                                            htmlFor="upload-input"
                                                            className="text-lg cursor-pointer bg-none hover:bg-none border-none focus:bg-none font-bold text-cal-primary-celadon-green-01A7A3 shadow-none focus:ring-0"
                                                        >
                                                        <span className="pl-2 text-lg">
                                                            {t('Take new picture')}
                                                        </span>
                                                            <input id="upload-input" name="upload-input[]" type="file"
                                                                   accept="image/*,application/pdf"
                                                                   multiple onChange={handleTakeNewPicture}
                                                                   className="sr-only"/>
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>) : (
                            <>
                                <div className="flex items-center justify-center">
                                    <div className="px-4 pt-14 md:pt-32">
                                        <div
                                            className="text-xl font-bold tracking-wide text-center md:text-2xl text-cal-primary-gunmetal-black-202833 px-6">
                                            {t('Take a photo of your parents\' letter')}
                                        </div>
                                        <div className="space-y-2">
                                            <div className="pt-10">
                                                <div className="flex justify-center">
                                                    <label
                                                        htmlFor="capture-input"
                                                        className="text-center w-full px-12 flex-col space-y-4 py-4 flex-col justify-center cursor-pointer rounded-md font-semibold bg-cal-primary-celadon-green-00B5B1 focus-within:outline-none focus-within:ring-2 focus-within:ring-cal-primary-celadon-green-00B5B focus-within:ring-offset-2 hover:text-cal-primary-celadon-green-00B5B"
                                                    >
                                                        <CameraIcon className="h-20 w-20 text-white mx-auto"
                                                                    aria-hidden="true"/>
                                                        <span className="text-lg text-white"> {t("Take a picture")} </span>
                                                        <input id="capture-input" name="capture-input[]" type="file"
                                                               accept="image/*,application/pdf" multiple onChange={handleFileChange}
                                                               className="sr-only"/>
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="text-cal-primary-gunmetal-black-202833 font-bold text-lg text-center"> {t("or")}
                                            </div>
                                            <div>
                                                <div
                                                    className="w-full border-2 border-cal-primary-celadon-green-00B5B1 bg-white rounded-md py-2 px-1">
                                                    <label
                                                        htmlFor="upload-input"
                                                        className="flex justify-center cursor-pointer font-semibold text-cal-primary-celadon-green-00B5B1 focus-within:outline-none focus-within:ring-2 focus-within:ring-cal-primary-celadon-green-00B5B focus-within:ring-offset-2 hover:text-cal-primary-celadon-green-00B5B"
                                                    >
                                                        <CloudUploadIcon className="h-7 w-7" aria-hidden="true"/>
                                                        <span className="pl-2 text-lg">{t("Upload photo")}</span>
                                                        <input id="upload-input" name="upload-input[]" type="file"
                                                               accept="image/*,application/pdf" multiple onChange={handleFileChange}
                                                               className="sr-only"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                    </div>
                </>
            )}
        </AppLayout>
    );
};
