import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import {CheckIcon} from "@heroicons/react/outline";
import useGlobal from "../../../state/useGlobal";
import {STEP_CHECKOUT_DONE} from "../../../constants/checkoutSteps";
import toast from "react-hot-toast";
import Config from "../../../config";
import api from "../../../services/api";
import Loading from "../../../components/Loading";
import {useLocation} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import FAMILY_ICON from "../../../images/calcuso-ai/family-icon.png";
import WHATSAPP_ICON from "../../../images/calcuso-ai/whatsapp-icon.svg";

export default function ShareScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {calcusoAIShare} = globalState;
    const {uuid, order_id_hash} = calcusoAIShare;
    const {checkout} = globalState;
    const {store} = checkout;
    const [referCode, setReferCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(async () => {
        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "share",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        setLoading(true);
        globalActions.initCalcusoAI();
        globalActions.initCheckout();
        globalActions.initFakeCart();
        globalActions.initAdyenConfig();
        globalActions.setCheckout({
            currentCheckoutStep: STEP_CHECKOUT_DONE,
        });

        const urlParams = new URLSearchParams(location.search);
        const orderHashUrl = urlParams.get('order_id_hash');
        const orderIdHash = orderHashUrl ? orderHashUrl : order_id_hash;

        let response = await api.get('/calcuso-cashback/request/' + orderIdHash);

        setReferCode(response.data);
        setLoading(false);
    }, []);

    const shareToWhatsApp = () => {
        const message = t('Check it out!');
        const url = window.location.origin + '/#/share/' + uuid + '/?refer_code=' + referCode;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${message} ${url}`
        )}`;

        window.open(whatsappUrl, "_blank");
    };

    const handleShare = async () => {
        try {
            await navigator.clipboard.writeText(window.location.origin + '/#/share/' + uuid + '/?refer_code=' + referCode);
            toast.success(t('The link to the easy school order has been copied.'));
        } catch (error) {
            console.error('Error copying URL to clipboard:', error.message);
        }
    };

    const handleBackToWebshop = () => {
        window.location.href = Config.stores[store?.id];
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>{('Calcuso')}</title>
                    </Helmet>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={false} title={('CALCUSO')} subTitle={t('MatchBuddy')}>
                        </HeaderAI>
                        <div class="flex flex-col items-center flex-1 h-full px-6">
                            <div className={'w-11/12 pt-6 text-xl flex flex-col justify-center items-center space-y-4'}>
                                <div className={'pb-4 hidden sm:block'}>
                                    <h3 className={'text-2xl text-cal-primary-celadon-green-01A7A3 font-medium text-center'}>
                                        {t('Checkout')}
                                    </h3>
                                </div>
                                <CheckIcon className="h-12 w-12 mx-auto"/>
                                <div className={'flex flex-col font-medium space-y-4 justify-center items-center text-center'}>
                                    <p>
                                        {t('Thank you for your order.')}
                                    </p>
                                </div>
                                <div className="text-center text-base space-y-4 font-medium bg-white py-4 rounded-md px-4">
                                    <img src={FAMILY_ICON} className="w-16 h-16 mx-auto"/>
                                    <div>
                                        {t('Parents Help Parents')}
                                    </div>
                                    <div>
                                        {t('Share this school list and make it easier for others to order school supplies!')}
                                    </div>

                                    <button onClick={shareToWhatsApp}
                                            className={"flex bg-cal-green-3CD370 text-white px-4 py-2 rounded-md font-bold mx-auto"}>
                                        <img src={WHATSAPP_ICON} className="w-5 h-5"/>
                                        <span className="pl-1">
                                            {t('Share via WhatsApp')}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className={'flex flex-col justify-center items-center pt-6 space-y-4'}>
                                <span
                                    onClick={handleBackToWebshop}
                                    class="font-medium text-base md:text-lg w-full text-center cursor-pointer text-cal-primary-celadon-green-008E8D">
                                {t('Back to shop')}
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppLayout>
    );
};
