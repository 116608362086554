import {LANGUAGES} from "../constants/storeCode";

export const initialState = localStorage.getItem("store")
  ? JSON.parse(localStorage.getItem("store"))
  : 1;

export const setStore = (store, storeM2) => {
  store.setState({ store: storeM2 }, () =>
    localStorage.setItem("store", storeM2)
  );
};

export const initLanguage = (store) => {
    const locale = localStorage.getItem("i18nextLng");

    if (!locale || !LANGUAGES.hasOwnProperty(locale)) {
        localStorage.setItem("i18nextLng", "de");
    }
};

