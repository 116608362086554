import React from 'react';
import {useTranslation} from "react-i18next";
import {MinusIcon, PlusIcon, PlusSmIcon} from "@heroicons/react/outline";
import SecondaryButton from "../../../components/SecondaryButton";
import useGlobal from "../../../state/useGlobal";
import toast from "react-hot-toast";
import classNames from "classnames";
import {isItemInCart} from "../../../state/fakeCart";

export default function ButtonAddCart({item, aiItem}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const selectedItem = items[item?.product_id];
    const {calcusoAI} = globalState;
    const aiItems = calcusoAI.items;

    const handleAddToCart = () => {
        const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            price: item.product_price,
            qty: Number(aiItem.quantity) || 1,
            product: item,
            options: {},
            created_at: new Date().getTime(),
        };
        globalActions.addToCart(payload);

        if (aiItems?.length) {
            const itemsToHide = new Set();

            aiItems.forEach(ai => {
                if (ai.item_id <= aiItem.item_id && isItemInCart(globalState, ai)) {
                    itemsToHide.add(ai.item_id);
                }
            });

            if (itemsToHide.size > 0) {
                globalActions.setIsDisplayList(Array.from(itemsToHide), false);
            }
        }
    };

    const handleOnRemove = () => {
        globalActions.minusFromCart(selectedItem);
    };

    const handleAdd = () => {
        globalActions.addToCart(selectedItem);
    };

    return (
        <>
            {selectedItem ? (
                <span className="relative z-0 inline-flex rounded-lg shadow-sm">
                    <button
                        type="button"
                        onClick={() => handleOnRemove(item)}
                        className={classNames({
                            "relative inline-flex items-center md:px-4 md:py-2 px-3 py-1 text-sm font-medium border rounded-l-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                        })}
                    >
                    <MinusIcon className="w-4 h-4"/>
                    </button>
                    <button
                        type="button"
                        className="relative inline-flex items-center md:px-4 md:py-2 px-3 py-1 -ml-px text-sm font-medium border border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none "
                    >
                    {selectedItem?.qty}
                    </button>
                    <button
                        type="button"
                        onClick={() => handleAdd(item)}
                        className={classNames({
                            "relative disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center md:px-4 md:py-2 px-3 py-1 -ml-px text-sm font-medium border rounded-r-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                        })}
                    >
                    <PlusIcon className="w-4 h-4"/>
                    </button>
                </span>
            ) : (
                <SecondaryButton
                    type="button"
                    color="text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                    size={window.innerWidth > 640 ? "base" : "xs"}
                    onClick={handleAddToCart}
                >
                    <PlusSmIcon
                        className="w-5 h-5 mr-3 -ml-1 text-celadon-green-700"
                        aria-hidden="true"
                    />
                    <span>{t("add")}</span>
                </SecondaryButton>
            )}
        </>
    );
};
