export const SHIP_TO_SCHOOL_METHOD = "1";
export const SHIP_TO_PRIVATE_METHOD = "2";

export const SHIPPING_METHOD_TO_SCHOOL = "toSchool";
export const SHIPPING_METHOD_TO_PRIVATE = "toPrivate";

export const SHIPPING_METHODS = [
    SHIPPING_METHOD_TO_PRIVATE,
    SHIPPING_METHOD_TO_SCHOOL
];

export const SHIPPING_METHOD_LABEL = {
    [SHIPPING_METHOD_TO_SCHOOL]: "Delivery to the school address",
    [SHIPPING_METHOD_TO_PRIVATE]: "Delivery to private addresses",
};

export const SHIPPING_METHOD_DESCRIPTION = {
    [SHIPPING_METHOD_TO_SCHOOL]:
        "The collected orders are sent to the school after the order deadline and can be handed out by the teacher.",
    [SHIPPING_METHOD_TO_PRIVATE]:
        "We send the order immediately to the parents' home addresses.",
};

export const FREE_SHIPPING_AMOUNT = 50;
export const STANDARD_SHIPPING_AMOUNT = 4.90;