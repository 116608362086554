import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import {CheckIcon} from "@heroicons/react/outline";
import useGlobal from "../../../state/useGlobal";
import Config from "../../../config";
import Loading from "../../../components/Loading";

export default function ThankYouScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const {store} = checkout;
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        globalActions.initCalcusoAI();
        globalActions.initCheckout();
        globalActions.initFakeCart();
        globalActions.initAdyenConfig();
        setLoading(false);
    }, []);

    const handleBackToWebshop = () => {
        window.location.href = Config.stores[store?.id];
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>{('Calcuso')}</title>
                    </Helmet>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={false} title={('CALCUSO')} subTitle={t('MatchBuddy')}>
                        </HeaderAI>
                        <div class="flex flex-col items-center flex-1 h-full px-6">
                            <div className={'w-11/12 pt-6 text-xl flex flex-col justify-center items-center space-y-4'}>
                                <CheckIcon className="h-20 w-20 mx-auto"/>
                                <div className={'flex flex-col font-medium space-y-4 justify-center items-center text-center'}>
                                    <p>
                                        {t('Thank you for providing your email! We will send you the results as soon as the process is complete.')}
                                    </p>
                                    <p className={'text-center'}>
                                        {t('We appreciate your patience and look forward to delivering your results shortly.')}
                                    </p>
                                </div>
                            </div>
                            <div className={'flex flex-col justify-center items-center pt-6 space-y-4'}>
                                <span
                                    onClick={handleBackToWebshop}
                                    class="font-medium text-lg w-full text-center cursor-pointer text-cal-primary-celadon-green-008E8D">
                                        {t('Back to shop')}
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppLayout>
    );
};
