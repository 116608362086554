export const DE = "de";
export const FR = "fr";
export const PT = "pt";
export const EN = "en";

export const NL = "nl";
export const TR = "TR";
export const UA = "UA";
export const AR = "AR";

export const STORE = {
  1: DE,
  4: FR,
  2: PT,
  5: EN,
  6: NL,
};

export const LANGUAGES = {
    DE,
    EN,
    TR,
    UA,
    AR
};