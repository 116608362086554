import React from 'react';
import classNames from "classnames";
import {PlusCircleIcon, PlusIcon} from "@heroicons/react/solid";
import {useTranslation, Trans} from "react-i18next";
import ProductImage from "../../ProductImage";
import BigNumber from "bignumber.js";
import BADGE_CONSTANTS from "../../../constants/badge";
import Badge from "../../Badge";

export default function CardFreeProduct({freeProduct, cartTotal}) {
    const {t} = useTranslation();

    return (
        <div className="px-4 py-2 w-full rounded-lg md:px-8 md:pt-2 md:max-w-7xl relative md:pb-6">
            <div>
                <div className={classNames({
                    "rounded-md py-2 relative bg-gray-50": true,
                })}>
                    <div>
                        <div>
                            <div class="flex justify-between ">
                                <div class="flex space-x-2">
                                    <div>
                                        <div class="pl-2 flex md:h-full items-center">
                                            <div className={classNames({
                                                "flex justify-center items-center w-9 h-9 rounded-full font-medium": true,
                                            })}>
                                                <span>
                                                    <PlusCircleIcon className="w-8 h-8"/>
                                                </span>
                                            </div>
                                            <div className={classNames({
                                                "w-80 md:w-full truncate pl-2": true,
                                            })}>
                                                <span className="md:text-base text-sm font-bold"> {freeProduct.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <div className={classNames({
                                        "max-h-screen": true,
                                    })}
                                         style={{transition: 'max-height 0.6s ease-in-out'}}
                                         id={'product-card-' + freeProduct.id}>
                                        <div class="mx-2 pb-2">
                                            <div className={classNames({
                                                'grid grid-cols-3': true,
                                            })}>
                                                <div class="col-span-1 flex justify-center items-center relative">
                                                    <div
                                                        class="flex justify-center items-center"
                                                    >
                                                        <ProductImage
                                                            src={freeProduct?.image}
                                                            alt={freeProduct?.image}
                                                            size="w-auto h-40 md:my-auto md:w-auto md:h-40 cursor-pointer"/>
                                                    </div>
                                                </div>
                                                <div class="pl-2 col-span-2 flex flex-col text-cal-primary-gunmetal-black-202833 space-y-1 md:relative">
                                                    <div>
                                                        <Badge
                                                            color="bg-fiery-rose-500 text-white font-bold"
                                                            colorText={t('Secure a gift')}
                                                        >
                                                            <span>{t('Secure a gift')}</span>
                                                        </Badge>
                                                    </div>

                                                    <div class="flex flex-col text-sm pt-2">
                                                        <span>
                                                          <Trans
                                                              i18nKey="freeProductMessage"
                                                              values={{
                                                                  cartTotal: new BigNumber(cartTotal).toString(),
                                                                  freeProductName: freeProduct?.name
                                                              }}
                                                              components={{
                                                                  bold: <span className="font-bold" />,
                                                              }}
                                                          />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
