import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DE_LOGO from "../../countries/Deutschland.png";
import ARABIC_LOGO from "../../countries/Arabic.png";
import EN_LOGO from "../../countries/English.png";
import UA_LOGO from "../../countries/Ukraine.png";
import TR_LOGO from "../../countries/Turkey.png";
import classNames from "classnames";
import {initLanguage} from "../../state/store";

const languages = [
    {
        code: "de",
        name: "German",
        avatar: DE_LOGO,
    },
    {
        code: "uk",
        name: "English",
        avatar: EN_LOGO,
    },
    {
        code: "tr",
        name: "Turkish",
        avatar: TR_LOGO,
    },
    {
        code: "ar",
        name: "Arabic",
        avatar: ARABIC_LOGO,
    },
    {
        code: "ua",
        name: "Ukrainian",
        avatar: UA_LOGO,
    },

];

export default function LanguageSwitcherV2() {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

    useEffect(() => {
        initLanguage();
        const storedLanguage = localStorage.getItem("i18nextLng") || "de";
        const language = languages.find((lang) => lang.code === storedLanguage) || languages[0];

        setSelectedLanguage(language);

        if (i18n.language !== storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, []);

    const handleOnChange = (e) => {
        setSelectedLanguage(e);
        i18n.changeLanguage(e.code);
    };

    return (
        <div className="text-center space-y-4">
            <span className="font-medium">
                {t('Select Language')}
            </span>
            <div className="flex justify-between items-center px-10">
                { languages.map((language) => (
                    <div key={language.code}
                         className={classNames({
                             "flex items-center cursor-pointer": true,
                             "border-2 border-cal-primary-celadon-green-00B5B1 rounded-full": selectedLanguage.code === language.code,
                         })}
                         onClick={() => handleOnChange(language)}>
                        <img src={language.avatar} alt={language.name} className="w-8 h-8 rounded-full" />
                    </div>
                ))}
            </div>
        </div>
    );
}
