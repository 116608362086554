import React, {useEffect, useState} from 'react';
import CHECK_GIF from "../../../images/calcuso-ai/IMG_0975.gif";
import LOADING from "../../../images/calcuso-ai/IMG_0976.gif";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import classNames from "classnames";

export default function Waiting({preCheckText}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [timeLeft, setTimeLeft] = useState(45);
    const [pointTimeSwitchStep, setPointTimeSwitchStep] = useState(0);

    useEffect(() => {
        setPointTimeSwitchStep(15);

        if (timeLeft <= 0) return;

        const timer = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    return (
        <div className={classNames({
            'flex flex-col h-full space-y-4 pt-10': true,
            'hidden': globalState.calcusoAI.status === 'completed'
        })}>
            <div className="flex justify-center items-center flex-1">
                <div className="mx-auto w-full md:w-1/2 lg:w-1/4 px-8 space-x-2">
                    <div className="relative">
                        <div className={classNames({
                            'absolute text-center w-full space-y-4': true,
                            'transition-opacity duration-500 opacity-0': pointTimeSwitchStep > timeLeft,
                            'transition-opacity duration-500 opacity-100': pointTimeSwitchStep <= timeLeft,
                        })}>
                            <img src={CHECK_GIF} className="w-24 mx-auto"/>
                            <div className="text-4xl font-bold text-cal-primary-celadon-green-01A7A3">
                                {t("Let's go!")}
                            </div>
                            <div className="text-xl font-medium">
                                {t('I check your image and search for the first products.')}
                            </div>
                        </div>
                        <div className={classNames({
                            'absolute text-center w-full space-y-4': true,
                            'transition-opacity duration-500 opacity-100': pointTimeSwitchStep > timeLeft,
                            'transition-opacity duration-500 opacity-0': pointTimeSwitchStep <= timeLeft,
                        })}>
                            <img src={LOADING} className="w-28 mx-auto"/>
                            <div className="text-4xl font-bold text-cal-primary-celadon-green-01A7A3">
                                {t('Almost ready!')}
                            </div>
                            <div className="text-xl font-medium text-lg">
                                {preCheckText}
                            </div>
                        </div>
                        <div className="pt-72 mx-auto">
                            <div className="bg-white rounded-lg py-2 text-center">
                                <div className="font-bold text-3xl text-cal-primary-celadon-green-01A7A3">
                                    {timeLeft}
                                </div>
                                <div className="pt-1">
                                    {t('seconds')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}