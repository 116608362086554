import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";
import history from "../../../routes/history";
import useGlobal from "../../../state/useGlobal";
import Loading from "../../../components/Loading";
import classNames from "classnames";
import apiCRM from "../../../services/apiCRM";
import {findReferCode} from "../../../services/helpers";
import mixpanel from "mixpanel-browser";
import LanguageSwitcherV2 from "../../../components/AI/LanguageSwitcherV2";
import FreeShipBanner from "../../../components/FreeShipBanner/FreeShipBanner";

export default function  WelcomeScreen({ga4}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(true);
    const [previousParentLetter, setPreviousParentLetter] = useState([])

    useEffect(async () => {
        mixpanel.track_pageview({"page": "welcome"})

        let globalStateData = globalState;

        if (globalStateData.oldData?.previousParentLetter) {
            let previousParentLetter = globalStateData.oldData?.previousParentLetter.slice(-2);
            setPreviousParentLetter(previousParentLetter);
        }

        globalActions.initFakeCart();
        globalActions.initCheckout();
        globalActions.initCalcusoAI();

        await globalActions.setCalcusoAIShare({
            refer_code: findReferCode(document.URL),
        });

        setIsLoading(false);
    }, []);

    const handleNext = () => {
        history.push(`/upload/`);
    };

    const loadParentLetter = async (uuid) => {
        setIsLoading(true);

        let response = await apiCRM.get('/ai/request/' + uuid);

        await globalActions.initFakeCart();
        await globalActions.reStructureCalcusoAI(response.data);
        await globalActions.setCalcusoAIShare({
            uuid: uuid,
        });
        globalActions.setStep('product_tag_detect');

        history.push('/product');
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>{('Calcuso')}</title>
                    </Helmet>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={false} title={('CALCUSO')} subTitle={t('MatchBuddy')} withLanguageSwitcher={true}>
                        </HeaderAI>
                        <div class="flex justify-between md:justify-center md:items-center md:w-2/3 lg:w-1/3 md:mx-auto flex-1">
                            <div className={classNames({
                                "md:pt-0": true,
                                "pt-12": previousParentLetter.length > 0,
                                "pt-36": previousParentLetter.length === 0
                            })}>
                                <div
                                    class="text-3xl font-bold tracking-wide text-center text-cal-primary-gunmetal-black-202833 px-6">
                                    {t('Let’s search the matching products from your school letter.')}
                                </div>
                                <div class="md:w-52 mx-auto pt-6 px-10">
                                    <Button
                                        type="button"
                                        color="bg-teal-500 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 focus:ring-cal-primary-celadon-green-00B5B1"
                                        size={window.innerWidth < 640 ? "base" : "xl"}
                                        full={true}
                                        onClick={handleNext}
                                    >
                                        {t('Start')}
                                    </Button>
                                </div>

                                {previousParentLetter.length > 0 && (
                                    <div class="max-w-2xl">
                                        <div class="pt-8 font-bold text-xl text-center">{t('Previous uploaded')}</div>
                                        <div className={`${previousParentLetter.length < 3 ? `justify-center` : `md:grid md:grid-cols-2 lg:grid lg:grid-cols-3`} overflow-x-auto md:overflow-x-hidden flex flex-row h-48 px-2 gap-3`}>
                                            {previousParentLetter.map((item, index) => (
                                                <div class="h-full inline-block pt-3">
                                                    <div onClick={() => {loadParentLetter(item.uuid)}} class={"max-h-32 h-full px-1.5 w-32 mx-auto bg-white rounded-lg shadow-md"}>
                                                        <div class="w-full h-full flex content-center">
                                                            <img className={`max-w-48 max-h-28 transform m-auto`} key={index}
                                                                 src={item.imageUrl}
                                                                 alt={`Image ${index}`}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div class="fixed inset-x-0 bottom-5 w-full md:w-1/2 lg:w-1/3 mx-auto">
                                    <LanguageSwitcherV2 />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppLayout>
    );
};
