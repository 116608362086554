import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import {PRODUCT_TYPE_CONFIGURABLE} from "../../../constants/common";
import FakeCartItemConf from "../../../components/FakeCartModal/FakeCartItemConf";
import FakeCartItem from "../../../components/FakeCartModal/FakeCartItem";
import BigNumber from "bignumber.js";
import useGlobal from "../../../state/useGlobal";
import {Link} from "react-router-dom";
import {ArrowRightIcon} from "@heroicons/react/outline";
import api from "../../../services/api";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
import Loading from "../../../components/Loading";
import history from "../../../routes/history";
import mixpanel from "mixpanel-browser";
import CartItemFree from "../../../components/FakeCartModal/CartItemFree";
import FreeShipBanner from "../../../components/FreeShipBanner/FreeShipBanner";
import {FREE_SHIPPING_AMOUNT, STANDARD_SHIPPING_AMOUNT} from "../../../constants/shippingMethod";

export default function CartScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [shippingCost, setShippingCost] = useState(STANDARD_SHIPPING_AMOUNT);

    useEffect(() => {
        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "cart",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        const {items} = fakeCart;

        const cartItems = Object.keys(items)
            .map((key) => {
                return {
                    ...items[key],
                };
            })
            .sort(function (x, y) {
                return x.created_at - y.created_at;
            });

        setCartItems(cartItems);
        const total = cartItems.reduce((accumulator, {price, qty}) => {
            return new BigNumber(accumulator).plus(
                new BigNumber(price).multipliedBy(new BigNumber(qty))
            );
        }, 0);

        setTotal(total);

        if (total && total.isGreaterThanOrEqualTo(FREE_SHIPPING_AMOUNT)) {
            setShippingCost(0);
        } else {
            setShippingCost(STANDARD_SHIPPING_AMOUNT);
        }

        setIsLoading(false);
    }, [globalState, fakeCart]);

    const handleCheckout = async () => {
        setIsCheckoutLoading(true);
        const {fakeCart} = globalState;
        const {items} = fakeCart;
        try {
            const cartItems = Object.keys(items).map((key) => {
                const {type, product, options, qty} = items[key];
                if (type === PRODUCT_TYPE_CONFIGURABLE) {
                    const configurable_item_options = product.configurable_product_options.map(
                        (item) => {
                            return {
                                option_id: item.attribute_id,
                                option_value: options[item.attribute_code],
                            };
                        }
                    );

                    const custom_options = product.product_custom_options.map(
                        (product_custom_option) => {
                            return {
                                option_id: product_custom_option.option_id,
                                option_value: options?.gravur,
                            };
                        }
                    );

                    return {
                        sku: product.product_sku,
                        product_type: product.product_type,
                        qty: qty,
                        product_option: {
                            extension_attributes: {
                                configurable_item_options,
                                custom_options,
                            },
                        },
                    };
                } else {
                    return {
                        sku: product.product_sku,
                        product_type: product.product_type,
                        qty: qty,
                    };
                }
            });

            let tokenData = await api.post(`/guest-carts/`);

            globalActions.setCheckout({
                quote_id: tokenData.data,
            });

            let cartData = await api.post(
                `/guest-carts/${tokenData.data}/add-items-ai`,
                {},
                {
                    cartItems,
                }
            );

            const {data} = cartData;
            const {success, message} = data;
            if (!success) {
                toast.error(t(message));
                setIsCheckoutLoading(false);
                return;
            }
            setIsCheckoutLoading(false);

            // Init Addresses
            globalActions.initAddresses();
            history.push("/checkout/address");
        } catch (error) {
            toast.error(t("Something went wrong"));
            Sentry.setUser(globalState);
            Sentry.captureException(error);
            this.setState({
                isCheckoutLoading: false,
            });
        }
        setIsCheckoutLoading(false);
    };

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {isLoading ? (
                <Loading/>
            ) : (<>
                <Helmet>
                    <title>{('Calcuso')}</title>
                </Helmet>
                <div className="flex flex-col h-full min-h-screen">
                    <div className="sticky top-0 bg-cal-primary-celadon-green-E5F8F7 z-20">
                        <HeaderAI confirm={true} title={('CALCUSO')} subTitle={t('MatchBuddy')} isShowGoBackButton={false}>
                        </HeaderAI>
                    </div>

                    <div className="max-w-2xl w-full px-4 py-4 mx-auto space-y-4 md:py-8 sm:px-6 lg:max-w-3xl lg:px-8">
                        <div className="w-full p-6 pb-20 space-y-8 bg-white rounded-lg">
                            <h3 className="text-xl font-medium leading-6 text-center ">
                                {t("Cart")}
                            </h3>
                            <div className="min-w-full px-2 m-auto">
                                <div className="flow-root">
                                    <ul className="-my-6 divide-y-2 divide-floral-white-500">
                                        {cartItems.length > 0 ? (
                                            cartItems.map((item) =>
                                                item.type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                    <FakeCartItemConf key={item.id} item={item}/>
                                                ) : (
                                                    <FakeCartItem key={item.id} item={item}/>
                                                )
                                            )
                                        ) : (
                                            <li className="flex items-center justify-center py-6">
                                                <p>{t("No items")}</p>
                                            </li>
                                        )}
                                        <CartItemFree />
                                    </ul>
                                </div>
                            </div>
                            <div className="py-6 border-t-2 border-gunmetal-black-500 sm:px-6">
                                <div className="flex justify-between text-base md:text-xl text-gunmetal-black-500">
                                    <p>{t("Total")}</p>
                                    <p className="font-medium">{new BigNumber(total).toFormat(2).toString()} €</p>
                                </div>
                                {total > 0 && (
                                    <>
                                        <div className="flex justify-between text-base md:text-xl text-gunmetal-black-500 pt-3">
                                            <p>{t("Shipping costs")}</p>
                                            <div className="flex">
                                                {shippingCost !== 0 ? (
                                                    <>
                                                        <p className="pl-1 font-medium">{new BigNumber(shippingCost).toFormat(2).toString()} € </p>
                                                    </>
                                                ) : (
                                                    <p className="font-medium text-cal-primary-celadon-green-008E8D">
                                                        {t('free')}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-sm italic font-medium">
                                                ({t('Free shipping from {{freeShippingAmount}} €', {freeShippingAmount: FREE_SHIPPING_AMOUNT})})
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="fixed inset-x-0 bottom-0">
                            <div
                                className="border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                                <div
                                    className="max-w-2xl px-4 py-2 mx-auto lg:max-w-7xl md:px-54 md:py-6 lg:px-8 lg:py-8">
                                    <div className="flex items-center justify-end space-x-4">
                                        <Link
                                            to={'/product'}
                                            className="font-medium cursor-pointer text-gunmetal-black-500"
                                        >
                                            {t("back")}
                                        </Link>
                                        <Button
                                            id="preview-screen-next-button"
                                            type="button"
                                            onClick={handleCheckout}
                                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                            size={window.innerWidth < 640 ? "base" : "xl"}
                                            loading={isCheckoutLoading}
                                        >
                                            <span>{t("checkout")}</span>
                                            <ArrowRightIcon
                                                className="w-5 h-5 ml-3 -mr-1 "
                                                aria-hidden="true"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
        </AppLayout>
    );
};
